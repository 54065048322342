import React from "react";
import { graphql, StaticQuery } from "gatsby";
import theme from "../theme/theme.yaml";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";
import Seo from "../components/Seo";
import { FaTag, FaRss, FaPaperPlane } from "react-icons/fa";
import config from "../../content/meta/config";
import { Link } from "gatsby";

const GamePage = (props) => {
  return (
    <React.Fragment>
      <Article theme={theme}>
        <header>
          <Headline title="ゲームハードの一覧" theme={theme} />
        </header>
        <section
          style={{
            margin: "2em 0",
            padding: "2em",
            backgroundColor: "#f5f5f5",
            borderRadius: "5px",
            lineHeight: "1.5",
          }}
        >
          <h1 style={{ textAlign: "center", color: "#333", marginBottom: "1em" }}>
            ゲーム機の性能を見てみよう
          </h1>
          <div style={{ textAlign: "justify" }}>
            <p>
              ゲーム機は、私たちに楽しいゲームを提供するために開発されたデバイスです。多くの人々が長時間をかけて開発し、その緻密な構造や性能は非常に魅力的であり、その開発プロセスには驚きのエピソードが数多く詰まっています。
            </p>
            <p>
              今やPCやスマートフォンが主流の時代ですが、各時代の最先端技術を凌ぐゲーム機の内部をじっくりと眺めてみて、その進化の歴史を楽しみながら比較してみてください。
            </p>
          </div>
        </section>
        <section
          style={{
            margin: "2em 0",
            padding: "2em",
            backgroundColor: "#f5f5f5",
            borderRadius: "5px",
            lineHeight: "1.5",
          }}
        >
          <h2 style={{ marginBottom: "0.5em" }}>任天堂</h2>
          <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
            <li>
              <Link to="/game/nintendo-switch">Nintendo Switch</Link>
            </li>
            <li>Wii U</li>
            <li>Nintendo 3DS</li>
            <li>Wii</li>
            <li>ニンテンドーDS</li>
            <li>GameCube</li>
            <li>NINTENDO64</li>
            <li>ゲームボーイアドバンス</li>
            <li>ゲームボーイ</li>
            <li>スーパーファミコン</li>
            <li>ファミリーコンピュータ</li>
          </ul>
          <h2 style={{ color: "#333", marginTop: "1.0em", marginBottom: "0.5em" }}>
            SCE (SONY Computer Entertainment)
          </h2>
          <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
            <li>PlayStation 4</li>
            <li>PlayStation Vita</li>
            <li>PlayStation 3</li>
            <li>PlayStation Portable</li>
            <li>PlayStation 2</li>
            <li>PlayStation</li>
          </ul>
          <h2 style={{ color: "#333", marginTop: "1.0em", marginBottom: "0.5em" }}>Microsoft</h2>
          <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
            <li>Xbox One</li>
            <li>Xbox 360</li>
            <li>Xbox</li>
          </ul>
          <h2 style={{ color: "#333", marginTop: "1.0em", marginBottom: "0.5em" }}>SEGA</h2>
          <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
            <li>DreamCast</li>
            <li>SEGA SATURN</li>
            <li>メガドライブ</li>
            <li>ゲームギア</li>
            <li>セガ・マークIII</li>
            <li>SG-1000</li>
          </ul>
          <h2 style={{ color: "#333", marginTop: "1.0em", marginBottom: "0.5em" }}>
            NECホームエレクトロニクス
          </h2>
          <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
            <li>PC-FX</li>
            <li>PCエンジン スーパーグラフィックス</li>
            <li>PCエンジン</li>
          </ul>
          <h2 style={{ color: "#333", marginTop: "1.0em", marginBottom: "0.5em" }}>SNK</h2>
          <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
            <li>ネオジオポケット</li>
            <li>NEO-GEO</li>
          </ul>
        </section>
      </Article>
      <Seo pageTitle="Game" />
    </React.Fragment>
  );
};

export default GamePage;
